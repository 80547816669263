.language-switcher-container {
    display: block;
    align-items: center;
    float: left;
    padding: 0 20px 0 5px;

    .btn-lang.active {
        color: #00509c;

        span {
            border-bottom: 1px solid #00509c;
        }
    }
}

.language-switcher-divider {
    margin: 0;
    background-color: #d9d9d9;
}
@primary-color: #046CC1;@link-color: #1890ff;