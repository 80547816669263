.site-layout-footer {
    display: table;
    position: relative;
    text-align: center;
    vertical-align: middle;
    height: 20px;
    background: #fff;
    white-space: nowrap;
    min-width: 300px;
    padding: 20px 0;
}
@primary-color: #046CC1;@link-color: #1890ff;