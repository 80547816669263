.merchant-select, .pymt-method-select {
    margin-bottom: 12px;

    .ant-select {
        width: 100%;
    }
}

.dashboard-card {
    box-shadow: 2px 2px 4px 0px #cdcdcd;

    .main-title {
        display: flex;
        justify-content: space-between;
    }

    .custom-divider {
        width: 100%;
        height: 1px;
        margin: 4px 0;
        background-color: #cdcdcd;
    }

    .total-record {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }
}

.chart-part {
    margin-top: 20px;

    .chartbox {
        box-shadow: 2px 2px 4px 0px #cdcdcd;
    }
}

.navigate-option {
    cursor: pointer;
}


.text-right {
    text-align: right;
}

.text-20 {
    font-size: 22px;
}
@primary-color: #046CC1;@link-color: #1890ff;